@import "../../../mixins.scss";

.ScansTable-table {
    @include holdens-styled-table;

    ul.ant-pagination {
        @include holdens-styled-pagination;
    }

    &::after {
        // background-image: linear-gradient(to bottom, rgba(14, 38, 59, 0) 0%, var(--color-primary-02) 100%);
        width: 100%;
        height: 100px;
        position: absolute;
        // bottom: 64px;
        pointer-events: none;
    }

    &.no-shadow::after {
        background: none;
    }

    & .ant-table-body {
        overflow-y: auto !important;
    }

    & .ant-pagination-total-text {
        font-size: 15px !important;
        line-height: 33px !important;
    }
}
