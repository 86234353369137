@import "react-datepicker/dist/react-datepicker";


.react-datepicker-wrapper {
    height: 40px;
    cursor: pointer;

    & .react-datepicker__input-container {
        & input {
            width: calc(100% - 30px);
            height: 40px;
            background: var(--color-extended-01);
            border: 1px solid var(--color-white);
            border-radius: 4px;
            font-family: Interstate;
            font-style: normal;
            font-weight: 100;
            font-size: 12px;
            padding: 0 15px;
            outline: none;
            color: var(--color-white);
            cursor: pointer;

            &::placeholder {
                color: var(--color-neutral-07);
            }
        }
    
    }
}

.react-datepicker__tab-loop {
    & .react-datepicker__tab-loop__start {}        

    & .react-datepicker-popper {
        padding-top: 0;
        width: auto;
        z-index: 9999;

        & .react-datepicker {
            background-color: var(--color-primary-01);
            box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .48), 0 6px 16px 0 rgba(0, 0, 0, .32), 0 9px 28px 8px rgba(0, 0, 0, .2);
            color: var(--color-white);
            border: 0;
            font-family: "Interstate";
            font-style: normal;
            padding-bottom: 20px;
            width: 250px;

            & .react-datepicker__triangle {
                display: none;
            }

            & .react-datepicker__navigation {
                top: 8px;

                & .react-datepicker__navigation-icon--previous::before {
                    border-color: var(--color-white);
                }

                &.react-datepicker__navigation--previous {
                    border-color: var(--color-white);
                    left: 25px;
                }

                &.react-datepicker__navigation--next {
                    border-color: var(--color-white);
                    left: 194px;
                }
            }
            
            & .react-datepicker__month-container {
                width: 100%;

                & .react-datepicker__header {
                    background-color: var(--color-primary-01);
                    color: var(--color-white);
                    font-family: "Interstate";
                    font-size: 0.74rem;
                    font-weight: 500;
                    font-style: normal;
                    border-bottom: 0px;

                    & .react-datepicker__current-month {
                        color: var(--color-white);
                        font-family: "Interstate";
                        font-size: 14px;
                        font-weight: 500;
                        padding: 5px 0;
                    }

                    & .react-datepicker__header__dropdown {
                        & .react-datepicker__year-dropdown-container {
                            & .react-datepicker__year-read-view {
                                & .react-datepicker__year-read-view--selected-year {
                                    display: inline-block;
                                    font-size: 14px;
                                    padding: 5px 0;
                                }

                                & .react-datepicker__year-read-view--down-arrow {
                                    border-color: var(--color-white);
                                    height: 3px;
                                    top: 12px;
                                    width: 3px;

                                    &::before {
                                        // content: '\f0d7';
                                        // font-family: "Font Awesome\ 5 Free" !important;
                                        // font-weight: 600;
                                        // position: relative;
                                        // left: 6px;
                                    }
                                }
                            }

                            & .react-datepicker__year-dropdown {
                                background-color: var(--color-primary-01);

                                & .react-datepicker__year-option:hover {
                                    background-color: #8FA1B1;
                                }
                            }
                        }
                    }

                    & .react-datepicker__day-names {
                        & .react-datepicker__day-name {
                            color: #df134c;
                            display: inline-block;
                            font-size: 14px;
                            margin-left: 0;
                            margin-right: 0;
                            min-width: 23px;   
                        }
                    }
                }

                & .react-datepicker__month {
                    & .react-datepicker__month-text,
                    & .react-datepicker__quarter-text {
                        width: 5rem;
                        font-family: "Interstate";
                        font-style: normal;
                        font-weight: 500;                    
                    }

                    & .react-datepicker__month-text {
                        min-width: 22px;
                        height: 22px;
                        line-height: 22px;
                        border-radius: 12px;
                        margin: 0;
                        background-color: transparent;
                        color: var(--color-white);

                        &:hover {
                            background-color: #8FA1B1;
                            border-radius: 15px;                        
                        }
                    }

                    &.react-datepicker__month-text--keyboard-selected {
                        background-color: transparent;
                        color: var(--color-white);
                    }

                    & .react-datepicker__week {
                        & .react-datepicker__day {
                            display: inline-block;
                            color: var(--color-white);
                            min-width: 23px;
                            font-size: 14px;
                            margin: 0.166rem 0px;
                            background-color: transparent;
                            border-radius: 30px;

                            &:hover {
                                background-color: #8FA1B1;
                                border-radius: 30px;                            
                            }

                            &.react-datepicker__day--in-range {
                                background: #409bef;
                                border-radius: 0;
                            }

                            &.react-datepicker__day--range-start {
                                background: #0583f7;
                                color: var(--color-white);
                            }
        
                            &.react-datepicker__day--disabled {
                                color: #8FA1B1;
                                background-color: transparent;

                                &:hover {
                                    background-color: transparent;
                                }                                
                            }
        
                            &.react-datepicker__day--today {
                                color: #40A9FF;
                                background-color: transparent;
                            }
        
                            &.react-datepicker__day--range-start,
                            &.react-datepicker__day--range-end,
                            &.react-datepicker__day--highlighted {
                                color: var(--color-white);
                                background: #0583f7;
                                border-radius: 30px;
                            }
        
                            &.react-datepicker__day--range-start {
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
        
                            &.react-datepicker__day--range-end {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }

                            &.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--highlighted {
                                border-radius: 30px;
                            }

                            &.react-datepicker__day--outside-month {
                                visibility: hidden;
                            }
                        }
                    }

                    & .react-datepicker__month--range-start,
                    & .active-date {
                        background: #0583f7;
                        color: var(--color-white);
                    }

                    & .react-datepicker__month--disabled {
                        color: #8FA1B1;
                        background-color: transparent;
                    }

                    & .react-datepicker__month-text--today {
                        color: #40A9FF;
                        background-color: transparent;
                    }

                    & .react-datepicker__month--range-start,
                    & .react-datepicker__month--range-end {
                        color: var(--color-white);
                        background: #0583f7;
                        border-radius: 30px;
                    }

                    & .react-datepicker__month--range-start {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    & .react-datepicker__month--range-end {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }
    }
}

// not found
.react-datepicker__month-wrapper {
    height: 40px !important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
}
