@import './colors.scss';

/*
*   Modal
*/

.ant-modal {
    & > .ant-modal-content {
        background-color: var(--color-primary-02);
        padding: 14px 0 !important;
    }
    
}

/*
*   Collapse
*/

.ant-collapse .ant-collapse-item:last-child >.ant-collapse-content {
    border-radius: 0;
}

/*
*   Select
*/

div.ant-select-dropdown {
    border: 1px solid var(--color-white);
    border-radius: 4px;

    & .ant-select-item-option-content {
        font-size: 12px;
        font-weight: 100;
        height: 24px;
        line-height: 24px;
    }

    & div[aria-selected=true].ant-select-item-option-active:not(.ant-select-item-option-disabled),
    & div[aria-selected=true].ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: var(--color-accent-01);
    }
}

.ant-select-selector {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    & .ant-select-selection-item {
        color: var(--color-white);
        font-size: 12px;    
        font-weight: 100;
    }
}

.ant-select-multiple .ant-select-selection-overflow {
    & .ant-select-selection-item {
        background-color: var(--color-neutral-01);
        border:1px solid var(--color-white);
        color: var(--color-white);
        font-size: 12px;    
        font-weight: 100;
        height: 24px;
        line-height: 24px;

        & .ant-select-selection-item-remove {
            & .anticon-close {
                color: var(--color-white);
                font-size: 12px;    
            }
        }

        & .ant-select-selection-item-content {
            font-weight: 100;
        }
    }
}

/*
*   Form Label / Label
*/

.ant-form-item-label {
    & label {
        font-family: 'Interstate';
        font-size: 14px;
        font-weight: bold;
    }
}

/*
*   Button
*/

button.ant-btn {
    text-transform: uppercase;
    font-family: 'Interstate';
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.7px;

    &:focus {
        border: 2px solid var(--color-white);
    }

    &[disabled] {
        background-color: var(--color-accent-03);
        border-color: var(--color-accent-03);
        color: var(--color-neutral-07);
    }

    &.ant-btn-default {
        background-color: var(--color-primary-02);

        &[disabled] {
            border-color: var(--color-accent-03);
            color: var(--color-accent-03);
        }
    }
}

/*
* Switch
*/

.ant-switch {
    border: 2px solid var(--color-white);
    background-color: var(--color-primary-02);
    height: 25px;
    &.ant-switch-checked {
        background-color: #0c75df;
        & .ant-switch-handle::before {
            background-color: var(--color-white) !important;
        }
    }
    & .ant-switch-handle::before {
        background-color: var(--color-white) !important;
    }
}

.ant-switch:not(.ant-switch-checked):hover {
    background-color: var(--color-primary-02) !important;
}

/*
*   Form
*/

form {
    & .ant-form-item,
    & .ant-form-item .ant-form-item-row {
        & .ant-form-item-label {
            padding-bottom: 2px;

            & > label {
                color: var(--color-white);
            }
        }
    }
}

.ant-form-item-label {
    label {
        // color: var(--color-white) !important;
    }
}

.ant-select-in-form-item {
    .ant-select-selector {
        background-color: var(--color-extended-01) !important;
        .ant-select-selection-item {
            color: var(--color-white) !important;
        }
    }
}

/*
* DatePicker
*/

.ant-picker-dropdown {
    & .ant-picker-panel-container {
        & .ant-picker-panel-layout {
            & .ant-picker-panel,
            & .ant-picker-panels {
                & .ant-picker-date-panel {
                    & > .ant-picker-header {
                        & button {
                            color: var(--color-white);

                            &.ant-picker-header-prev-btn,
                            &.ant-picker-header-super-prev-btn,
                            &.ant-picker-header-next-btn,
                            &.ant-picker-header-super-next-btn {
                                font-size: 20px;
                            }
                        }
                    }

                    & > .ant-picker-body {
                        & > table.ant-picker-content {
                            & th {
                                color: var(--color-red-01);
                                text-transform: uppercase;
                            }

                            & td {
                                &.ant-picker-cell {
                                    color: var(--color-accent-03);
                                    
                                    & > .ant-picker-cell-inner,
                                    & > .ant-picker-cell-inner::before {
                                        border-radius: 15px;
                                    }    

                                    &:hover {
                                        & > .ant-picker-cell-inner {
                                            background-color: var(--color-accent-01);
                                        }
                                    }

                                    &.ant-picker-cell-range-start {
                                        &::before {
                                            background-color: var(--color-accent-01);
                                            border-radius: 15px 0 0 15px;
                                        }

                                        & > .ant-picker-cell-inner,
                                        & > .ant-picker-cell-inner::before {
                                            background-color: var(--color-accent-01);
                                            border-radius: 15px 0 0 15px;
                                        }    
                                    }

                                    &.ant-picker-cell-in-range {                                        
                                        &::before,
                                        & > .ant-picker-cell-inner,
                                        & > .ant-picker-cell-inner::before {
                                            background-color: var(--color-accent-01);
                                            border-radius: 0;
                                        }    
                                    }

                                    &.ant-picker-cell-range-end {
                                        &::before {
                                            background-color: var(--color-accent-01);
                                        }

                                        & > .ant-picker-cell-inner,
                                        & > .ant-picker-cell-inner::before {
                                            background-color: var(--color-accent-01);
                                            border-radius: 0 15px 15px 0;
                                        }    
                                    }

                                    &.ant-picker-cell-range-hover-start {
                                        &::after {
                                            border-radius: 15px 0 0 15px;
                                        }    
                                    }

                                    &.ant-picker-cell-range-hover-end {
                                        &::after {
                                            border-radius: 0 15px 15px 0;
                                        }    
                                    }

                                }

                                &.ant-picker-cell-in-view {
                                    color: var(--color-white);

                                    &.ant-picker-cell-today {
                                        border: 0;
                                        color: var(--color-accent-01);

                                        & > .ant-picker-cell-inner,
                                        & > .ant-picker-cell-inner::before {
                                            border: 0;
                                        }

                                        &:hover {
                                            color: var(--color-white);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-notification-notice {
    background: #fff !important;
    color: #000 !important;
    padding: 13px 16px !important;
}

.ant-notification-notice-message {
    color: #000 !important;
    font-weight: 700 !important;
    font-size: 15px !important;
}

.ant-notification-notice-description {
    font-family: 'InterstateLight';
    font-size: 14px !important;
}

.ant-notification-notice-icon {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
}

.ant-notification-notice {
    border-left: 12px solid #0C75DF;
    border-radius: 4px 0px 0px 4px !important;
    width: 540px !important;
}

.ant-notification-notice-success {
    border-left: 12px solid var(--color-success) !important;
}

.ant-notification-notice-icon-success svg {
    color: #fff;
    background: var(--color-success);
    border-radius: 13px;
    border: 1px solid var(--color-success);
}

.ant-notification-notice-error {
    border-left: 12px solid #fb4737 !important;
}

.ant-notification-notice-icon-error svg {
    color: #fff;
    background: #fb4737;
    border-radius: 13px;
    border: 1px solid #fb4737;
}

.ant-notification-notice-warning {
    border-left: 12px solid #ffc452 !important;
}

.ant-notification-notice-icon-warning svg {
    color: #fff;
    background: #ffc452;
    border-radius: 13px;
    border: 1px solid #ffc452;
}

.ant-notification-notice-content {
    width: 86% !important;
}

.ant-notification-notice-close {
    color: rgba(3, 22, 39, 0.35) !important;
    font-size: 14px !important;
    top: 0 !important;
    right: 0 !important;
    border-left: 1px solid rgba(3, 22, 39, 0.35) !important;
    height: 100% !important;
    width: 70px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.ant-modal-header {
    background: transparent !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    margin-bottom: 14px !important;
}

.ant-modal-body {
    padding: 24px 24px 28px !important;
    border: solid 1px rgba(255, 255, 255, 0.5) !important;
    border-left: 0 !important;
    border-right: 0 !important;
    & .ant-space {
        padding: 0 !important;
        & .ant-typography{
            font-size: 16px !important;
        }
    }
}

.ant-modal-confirm-body {
    padding: 15px !important;
    & .ant-typography{
        color: #fff !important;
        font-size: 16px !important;
    }
}

.ant-modal-footer, .ant-modal-confirm-btns {
    text-align: center !important;
    & button {
        border-radius: 4px !important;
        height: auto !important;
    }
    & .ant-btn-default {
        color: #fff !important;
        border: solid 2px #fff !important;
    }
    & .ant-btn-primary {
        margin-inline-start: 20px !important;
    }
}

.ant-input {
    height: 40px !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.selected-row {
    & .ant-input,
    .ant-select-selector {
        background-color: var(--color-primary-02) !important;
    }
}

.ant-upload-wrapper {
    width: 100%;
}

.ant-collapse-header {
    border: 0 !important;
}

.ant-select-disabled {
    & .ant-select-selector {
        opacity: 0.4;
    }
}

.ant-select-selection-overflow-item-rest {
    & .ant-select-selection-item {
        padding: 0 !important;

        & .ant-select-selection-item-content {
            margin: 0 !important;

            & .status-rest-count {
                padding: 0 10px;
            }
        }
    }
}

.ant-tooltip-inner {
    background-color: var(--color-primary-02) !important;
}

.ant-tooltip-arrow:before {
    background: var(--color-primary-02) !important;
}