@import '../../colors.scss';

.flowTasksWrapper {
    height: 86vh;
    width: 100%;
    background-color: var(--color-primary-01);

    & .default-collapse {
        border-radius: 0;
    
        & div.ant-collapse-header {
            border-radius: 0;
    
        }
    }

    & .ant-table-thead {
        & .ant-table-cell {
            border-bottom: 2px solid white;
        }
    }
    
    & .selectedRow {
        .ant-table-cell:not(.ant-table-cell-row-hover) { 
            background-color: $HIGHLIGHT_SELECTED_ROW;
            color: $TEXT_BUTTON_HOVER;
            span.ant-typography {
                color: $TEXT_BUTTON_HOVER;
            }
        }
    }
    
    & .ant-table-cell {
        cursor: pointer;
        border-start-start-radius: 0px !important;
        border-start-end-radius: 0px !important;
        border-end-start-radius: 0px !important;
        border-end-end-radius: 0px !important;
    }
    
    & .ant-table-cell-row-hover {
        background-color: var(--color-accent-03) !important;
        color: $TEXT_BUTTON_HOVER;
    }
    
    & .ant-tag-default {
        background-color: $BG_LAYOUT;
    }
    
    & .flow-tasks-content {
        border: none;
        .ant-collapse {
            width: 100%;
            border: none;
            border-radius: 0;
            background: transparent;
        }
        > .ant-collapse-item {
            > .ant-collapse-content {
                > .ant-collapse-content-box {
                    padding-right: 0;
                }
            }
        }
    }
    
    & .flow-tasks-form-collapse {
        padding-top: 8px;
        background-color: var(--color-primary-01);
    }
    
    & .flow-tasks-left-col {
        padding-right: 10;
        width: 100%;
        background-color: var(--color-primary-02);
        border-radius: 10px !important;
        & .ant-spin-container {
            // overflow-y: scroll;
            height: calc(100vh - 150px);
            display: flex;
            flex-direction: column;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            & .flow-tasks-left-col-content {
                border-radius: 10px;
                overflow-y: auto;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
            }
            /* Hide scrollbar for Chrome, Safari and Opera */
            & .flow-tasks-left-col-content::-webkit-scrollbar {
                display: none;
            }
            & .flow-tasks-left-col-button {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                height: 60px;
                position: absolute;
                bottom: 0px;
                width: 100%;
                z-index: 1;
                background-color: var(--color-primary-02);
                padding-top: 24px;
            }
        }
        /* Hide scrollbar for Chrome, Safari and Opera */
        & .ant-spin-container::-webkit-scrollbar {
            display: none;
        }
    }

    & .flow-tasks-right-col {
        padding-left: 20px;
    }
    
    & .flow-tasks-custom-tab {
        min-width: 100%;
        text-align: center;
        color: var(--color-white);
        text-align: center;
        background-color: inherit;
        font-weight: bold;
        border: solid 1px var(--color-white);
        float: right;
        padding: 4px 0px;
        border-radius: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 92px;
        padding-left: 4px;
        padding-right: 4px;
    }

    & .flow-tasks-custom-tab-inactive {
        color: $COLOR_NEUTRAL;
        border-color: $COLOR_NEUTRAL;
    }
    
    & .flow-tasks-button-wrapper {
        margin-top: 15px;
        width: 100%;
        padding-bottom: 16px;
    }

    & .flow-tasks-bounding-box-overlay-col {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--color-primary-01);
        padding: 15;
        + div {
            & div {
                border: 2px solid $HIGHLIGHT_SELECTED_ROW !important;
                box-shadow: none;
                box-sizing: unset;
                padding: 5px;
            }
        }
        & span:not(.anticon) {
            color: var(--color-white);
            font-family: Interstate;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            vertical-align: middle;
        }
    }

    & .flow-tasks-date-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        color: var(--color-white);
        font-family: Interstate;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        vertical-align: middle;
    }

    & .flow-tasks-show-deactivated-flows-text {
        padding-right: 8px;
        color: var(--color-white);
        font-family: Interstate;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
    }

    & .flow-tasks-empty-placehoder {
        padding-top: 8px;
        color: $TEXT_DARKER;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    & .flow-tasks-image-section::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & .flow-tasks-image-section {
        overflow-y: auto;
        height: calc(70vh - 128px);
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: var(--color-primary-01) !important;
        border-radius: 10px !important;
        border: 5px solid var(--color-extended-01) !important;
    }

    & .flow-tasks-table {
        height: 29vh;
        overflow-y: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        .ant-table {
            background-color: var(--color-primary-01);
            thead {
                position: sticky;
                top: 0;
                z-index: 1;
                tr {
                    th {
                        background-color: var(--color-primary-01);
                        color: white; //xxx
                    }
                }
            }
            tbody {
                tr {
                    background-color: var(--color-primary-01);
                }
                tr:nth-child(2n) { 
                    background-color: rgba(255, 255, 255, 0.2); //xxx
                }
            }
        }
    }

    & .ant-table-wrapper.flow-tasks-table-shadow:after  {
        background-image: linear-gradient(to bottom, rgba(3, 22, 39, 0), rgba(3, 22, 39, 0.01) 0%, var(--color-primary-01));
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 55vh;
        pointer-events: none;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    & .flow-tasks-table::-webkit-scrollbar {
        display: none;
    }

    & .flow-tasks-match-fields {
        padding-left: 12px;
        flex-grow: 1;
        overflow-y: hidden;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    & .flow-tasks-match-fields-shadow:after  {
        content: "";
        background-image: linear-gradient(to bottom, rgba(3, 22, 39, 0), rgba(3, 22, 39, 0.01) 0%, var(--color-primary-01));
        width: 43%;
        height: 100px;
        position: fixed;
        bottom: 100px;
        pointer-events: none;
        z-index: 2;
    }

    & .flow-tasks-match-fields-view-shadow:after  {
        background-image: none !important;
    }

    @media (min-width: 2300px) {
        .flow-tasks-match-fields {
            height: 91vh;
        }
    }
    @media (min-width: 768px) and (max-width: 2299px) {
        .flow-tasks-match-fields {
            height: 78vh;
        }
    }
    @media (min-width: 2300px) {
        .flow-tasks-match-fields-editable {
            height: 83vh;
        }
    }
    @media (min-width: 1291px) and (max-width: 2299px) {
        .flow-tasks-match-fields-editable {
            height: 82vh;
        }
    }
    @media (min-width: 768px) and (max-width: 1290px) {
        .flow-tasks-match-fields-editable {
            height: 85vh;
        }
    }

    & .flow-tasks-idle-icon {
        font-size: 20px;
        color: var(--color-yellow-02);
        display: flex;
        justify-content: center;
    }

    & .flow-tasks-idle-box {
        background-color: rgba(255, 230, 0, 0.35);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    & .flow-tasks-match-fields::-webkit-scrollbar {
        display: none;
    }

    & .flow-tasks-detail-header-row {
        background-color: var(--color-primary-02) !important;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: white;
        padding-right: 12px;
        .flow-tasks-detail-header {
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 24px;
            font-family: Interstate;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            article {
                color: white !important;
            }
        }
    }

    & .flow-tasks-dates {
        padding-bottom: 8px;
        padding-right: 12px;
        // color: $COLOR_NEUTRAL;
        font-family: Interstate;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(255, 255, 255, 0.8);//xxx
        & .flow-tasks-review-all-active-tag {
            padding: 9px;
        }
    }

    & .flow-tasks-title {
        color: var(--color-neutral-03);
    }

    & .flow-tasks-view-only-label {     
        color: $TEXT_DARKER;
        font-weight: normal;
        font-size: 12px;
    }

    & .flow-tasks-review-all {
        border: 2px solid var(--color-white);
        border-radius: 5px;
        padding: 10px;
        margin-right: 24px;
        margin-bottom: 24px;
    }    
    
    & .flow-tasks-review-all-active-tag {
        border-color: var(--color-yellow-02);
    }
    
    & .flow-tasks-review-all-active {
        border-color: var(--color-yellow-02);
    }

    & .flow-tasks-review-all-disabled {
        background-color: none;
        border-color: rgba(255, 255, 255, 0.25);
    }

    & .flow-tasks-review-all-scans-title {
        color: var(--color-white);
        font-family: Interstate;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    & .ant-spin-nested-loading {
        min-height: 100%;
    }

    & .ant-space-item {
        padding-right: 20px !important;
    }

    & .ant-select-disabled {
        .ant-select-selection-item {
            color: $TEXT_DARKER
        }
        .ant-select-selection-placeholder {
            color: $TEXT_DARKER
        }
    }

    & .ant-switch-disabled.ant-switch-checked {
        background-color: $COLOR_NEUTRAL;
    }
}

.flow-tasks-description {
    margin-bottom: 8px;
    color: var(--color-white);
    font-family: Interstate;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
}